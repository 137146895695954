import React, { useState, useEffect } from "react";
import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { getAllProductsFromUsers } from "../../api/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const ProductsFromUsers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const GetAllProductsFromUsers = async () => {
    try {
      const response = await getAllProductsFromUsers();
      const capitalizedProducts = response.data.map((product) => ({
        ...product,
        Name: product.Name?.charAt(0).toUpperCase() + product.Name?.slice(1),
        Brand: product.Brand?.charAt(0).toUpperCase() + product.Brand?.slice(1),
        deviceType:
          product.deviceType?.charAt(0).toUpperCase() +
          product.deviceType?.slice(1),
      }));
      setProducts(capitalizedProducts);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching products", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllProductsFromUsers();
  }, []);

  const handleViewClick = (id) => {
    navigate(`/products/view-product/${id}`);
  };

  const columns = [
    { field: "ProductID", headerName: "ID", flex: 0.5 },
    { field: "Name", headerName: "Product Name", flex: 1 },
    { field: "Brand", headerName: "Brand Name", flex: 1 },
    { field: "ModelNumber", headerName: "Model Number", flex: 1 },
    { field: "deviceType", headerName: "Device Type", flex: 1 },
    { field: "ProductWatt", headerName: "Energy", flex: 1 },
    {
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <IconButton onClick={() => handleViewClick(params.row.ProductID)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        padding: "20px",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box display="flex" justifyContent="left" alignItems="center">
        <Header
          title="PRODUCTS FROM USERS"
          subtitle="List of all products from users"
        />
      </Box>

      <Box
        height="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={products}
          columns={columns}
          getRowId={(row) => row.ProductID}
          components={{ Toolbar: GridToolbar }}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default ProductsFromUsers;
