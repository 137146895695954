import {
  Box,
  Button,
  TextField,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoById, updateVideo } from "../../api/axios";

const EditVideo = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedVideoDetails, setUpdatedVideoDetails] = useState({
    title: "",
    description: "",
    status: "",
    video: null,
  });
  const [errors, setErrors] = useState({});
  const [videoName, setVideoName] = useState("");

  const GetVideoById = async (id) => {
    try {
      const data = await getVideoById(id);
      if (data.status) {
        setUpdatedVideoDetails({
          title: data.result.title,
          description: data.result.description,
          status: data.result.status,
          video: data.result.video_url,
        });
        const videoUrl = data.result.video_url;
        const videoFileName = decodeURIComponent(
          videoUrl.split("/").pop().split("%20").pop().split("?")[0]
        );
        setVideoName(videoFileName);
      }
    } catch (error) {
      console.error("Error fetching video:", error);
    }
  };

  useEffect(() => {
    GetVideoById(id);
  }, [id]);

  const validateField = (name, value) => {
    const errors = {};

    if (name === "title" && !value) {
      errors.name = "Video title is required.";
    }
    if (name === "description" && !value) {
      errors.description = "Description is required.";
    }
    if (name === "video") {
      if (!value) {
        errors.video = "Video is required.";
      } else if (
        typeof value !== "string" &&
        !value.type.startsWith("video/")
      ) {
        errors.video = "Please enter a valid video type";
      }
    }

    return errors;
  };

  const handleInputChange = (name, value) => {
    setUpdatedVideoDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === "video" && value) {
      setVideoName(value.name);
    }
    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldErrors[name] || "",
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!updatedVideoDetails.title) {
      errors.title = "Video title is required.";
    }
    if (!updatedVideoDetails.description) {
      errors.description = "Description is required.";
    }
    if (!updatedVideoDetails.video) {
      errors.video = "Video is required.";
    } else if (
      typeof updatedVideoDetails.video !== "string" &&
      !updatedVideoDetails.video.type.startsWith("video/")
    ) {
      errors.video = "Please enter a valid video type";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateVideo = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const body = {
        title: updatedVideoDetails.title,
        description: updatedVideoDetails.description,
        status: updatedVideoDetails.status,
        video: updatedVideoDetails.video,
      };

      try {
        const response = await updateVideo(id, body);
        if (response.status) {
          setAlertSeverity("success");
          setAlertMessage("Video updated successfully");
          setTimeout(() => {
            navigate("/videos");
          }, 2000);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(`Failed video update: ${error.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box sx={{ padding: "20px", height: "90vh", overflowY: "auto" }}>
      <Header title={`UPDATE VIDEO ${id}`} subtitle="" />
      <Box mt="40px">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Title*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedVideoDetails.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedVideoDetails.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              multiline
              rows={5}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Upload a Video*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="video/*"
                onChange={(e) => handleInputChange("video", e.target.files[0])}
                name="video"
                error={!!errors.video}
                helperText={errors.video}
              />
              {videoName && (
                <Typography variant="subtitle1" mt={1}>
                  {`Current video: ${videoName}`}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateVideo}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditVideo;
