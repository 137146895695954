import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, Snackbar } from "@mui/material";
import Header from "../../components/Header";
import { getProductById, verifyProduct } from "../../api/axios.js";
import MuiAlert from "@mui/material/Alert";

function ViewProduct() {
  const { id } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const GetProductById = async (id) => {
    try {
      const data = await getProductById(id);
      if (data.status) {
        setProductDetails(data.result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetProductById(id);
  }, [id]);

  const handleAccept = async () => {
    setIsLoading(true);

    try {
      const response = await verifyProduct(id);
      if (response.success) {
        setAlertSeverity("success");
        setAlertMessage("Product verified successfully");
        setTimeout(() => {
          navigate("/products-from-users");
        }, 2000);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed verify product: ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Typography variant="h5" component="span" fontWeight="bold">
          Loading...
        </Typography>
      ) : (
        <Box sx={{ padding: "20px", overflowY: "auto", height: "90vh" }}>
          <Header
            title={
              productDetails.verified === true
                ? `View Product ${id}`
                : `Review Product ${id}`
            }
            subtitle=""
          />
          <Box mt={"40px"}>
            <Grid container>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Product Name:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {productDetails.Name?.charAt(0).toUpperCase() +
                      productDetails.Name?.slice(1)}
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Brand:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {productDetails.Brand?.charAt(0).toUpperCase() +
                      productDetails.Brand?.slice(1)}
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Model Number:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{productDetails.ModelNumber}</Typography>
                </Grid>
              </div>
            </Grid>
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Device Type:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{productDetails.deviceType}</Typography>
                </Grid>
              </div>
            </Grid>
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Energy Consumption:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{productDetails.ProductWatt}</Typography>
                </Grid>
              </div>
            </Grid>
            {/* <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Estimated Cost:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{productDetails.estimatedCost}</Typography>
                </Grid>
              </div>
            </Grid> */}
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Product Image:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <img
                    src={productDetails.proImage[0]}
                    alt={`Image of ${productDetails.Name}`}
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
              </div>
            </Grid>
            {productDetails.verified === false && (
              <Grid item xs={12} mt="20px">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    fontSize: "12px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                  onClick={handleAccept}
                >
                  {isLoading ? "Accepting..." : "Accept"}
                </Button>
              </Grid>
            )}
          </Box>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert
              onClose={() => setOpenSnackbar(false)}
              severity={alertSeverity}
              elevation={6}
              variant="filled"
              style={{ color: "white" }}
            >
              {alertSeverity === "success" ? "Success" : "Error"}
              {": "}
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Box>
      )}
    </div>
  );
}

export default ViewProduct;
