import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getVideoById } from "../../api/axios.js";

function ViewVideo() {
  const { id } = useParams();
  const [videoDetails, setVideoDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const GetVideoById = async (id) => {
    try {
      const data = await getVideoById(id);
      if (data.status) {
        setVideoDetails(data.result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching video:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetVideoById(id);
  }, [id]);

  return (
    <div>
      {loading ? (
        <Typography variant="h5" component="span" fontWeight="bold">
          Loading...
        </Typography>
      ) : (
        <Box sx={{ padding: "20px", overflowY: "auto", height: "90vh" }}>
          <Header title={`View Video ${id}`} subtitle="" />
          <Box mt={"40px"}>
            <Grid container>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Title:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {videoDetails.title?.charAt(0).toUpperCase() +
                      videoDetails.title?.slice(1)}
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Description:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {videoDetails.description?.charAt(0).toUpperCase() +
                      videoDetails.description?.slice(1)}
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{videoDetails.status}</Typography>
                </Grid>
              </div>
            </Grid>
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={2}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Video:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <video
                    controls
                    src={videoDetails.video_url}
                    alt="Video Thumbnail"
                    style={{
                      marginTop: "10px",
                      width: "500px",
                      height: "300px",
                    }}
                  />
                </Grid>
              </div>
            </Grid>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default ViewVideo;
