import { useState } from "react";
import { Box, Button, TextField, InputLabel, Snackbar } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { addNewProduct } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";

const AddNewProduct = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("Name", values.name);
    formData.append("Brand", values.brand);
    formData.append("ModelNumber", values.modelNumber);
    formData.append("ProductWatt", values.energyConsumption);
    formData.append("deviceType", values.deviceType);
    formData.append("proImage", values.image);

    try {
      const res = await addNewProduct(formData);
      if (res.status) {
        setAlertSeverity("success");
        setAlertMessage("Product added successfully");
        setTimeout(() => {
          navigate("/products");
        }, 2000);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new product : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflowY: "auto",
      }}
    >
      <Header title="ADD NEW PRODUCT" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Product Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Brand*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.brand}
                name="brand"
                error={!!touched.brand && !!errors.brand}
                helperText={touched.brand && errors.brand}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Device Type*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.deviceType}
                name="deviceType"
                error={!!touched.deviceType && !!errors.deviceType}
                helperText={touched.deviceType && errors.deviceType}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Energy Consumption(kWh/year)*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.energyConsumption}
                name="energyConsumption"
                error={
                  !!touched.energyConsumption && !!errors.energyConsumption
                }
                helperText={
                  touched.energyConsumption && errors.energyConsumption
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Model Number*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.modelNumber}
                name="modelNumber"
                error={!!touched.modelNumber && !!errors.modelNumber}
                helperText={touched.modelNumber && errors.modelNumber}
                sx={{ gridColumn: "span 4" }}
              />
              <Box sx={{ gridColumn: "span 4" }}>
                {" "}
                <InputLabel htmlFor="eventType">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "image",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Product name is required"),
  brand: yup.string().required("Brand is required"),
  modelNumber: yup.string().required("Model number is required"),
  energyConsumption: yup.string().required("Energy consumption is required"),
  deviceType: yup.string().required("Device type is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "Please enter a valid image type", (value) => {
      return !value || (value && value.type.startsWith("image/"));
    }),
});

const initialValues = {
  name: "",
  brand: "",
  modelNumber: "",
  energyConsumption: "",
  deviceType: "",
  image: null,
};

export default AddNewProduct;
