import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  Snackbar,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { addNewGift } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";

const AddNewGift = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("creditCount", values.creditCount);
    formData.append("isPhysicalGift", values.isPhysical);
    formData.append("image", values.image);

    try {
      const res = await addNewGift(formData);
      if (res.result.status) {
        setAlertSeverity("success");
        setAlertMessage("Gift added successfully");
        setTimeout(() => {
          navigate("/gifts");
        }, 2000);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to add new gift : ${error.message}`);
    } finally {
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflowY: "auto",
      }}
    >
      <Header title="ADD NEW GIFT" subtitle="" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Gift Name*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                multiline
                rows={5}
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Credit Count*"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.creditCount}
                name="creditCount"
                error={!!touched.creditCount && !!errors.creditCount}
                helperText={touched.creditCount && errors.creditCount}
                sx={{ gridColumn: "span 2" }}
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "E") {
                    e.preventDefault();
                  }
                }}
              />
              <FormControl
                fullWidth
                variant="filled"
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel>Gift Type(physical or not)*</InputLabel>
                <Select
                  value={values.isPhysical}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="isPhysical"
                  error={!!touched.isPhysical && !!errors.isPhysical}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
                {touched.isPhysical && errors.isPhysical && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    {errors.isPhysical}
                  </FormHelperText>
                )}
              </FormControl>
              <Box sx={{ gridColumn: "span 4" }}>
                {" "}
                <InputLabel htmlFor="eventType">Upload an Image*</InputLabel>
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: "image",
                        value: event.currentTarget.files[0],
                      },
                    })
                  }
                  name="image"
                  error={!!touched.image && !!errors.image}
                  helperText={touched.image && errors.image}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {isLoading ? "Adding..." : "Add"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("Gift name is required"),
  creditCount: yup
    .number()
    .required("Credit count is required")
    .typeError("Credit count must be a number")
    .min(1, "Credit count must be greater than 0"),
  isPhysical: yup.string().required("Type is required"),
  description: yup.string().required("Description is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test("fileType", "Please enter a valid image type", (value) => {
      return !value || (value && value.type.startsWith("image/"));
    }),
});

const initialValues = {
  name: "",
  creditCount: "",
  isPhysical: "",
  description: "",
  image: null,
};

export default AddNewGift;
