import axios from "axios";

const BASE_URL = "https://watch.hasthiya.org";
const token = localStorage.getItem("token");

export const signupURL = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
  },
});

export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export const multipartAPI = axios.create({
  baseURL: BASE_URL,
  timeout: 400000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  },
});

//login
export const SignIn = async (body) => {
  try {
    const res = await signupURL.post(`/admin/login`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//products
export const getAllProducts = async () => {
  try {
    const res = await API.get(`/api/products/getVerifiedProducts`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getProductById = async (id) => {
  try {
    const res = await API.get(`/api/products/getProductById/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllProductsFromUsers = async () => {
  try {
    const res = await API.get(`/api/products/getNotVerifiedProducts`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addNewProduct = async (formData) => {
  console.log("token", token);
  try {
    const res = await multipartAPI.post(
      `/api/products/addProductAdmin`,
      formData
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateProduct = async (id, body) => {
  try {
    const res = await multipartAPI.put(
      `/api/products/updateProductByID/${id}`,
      body
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const verifyProduct = async (id) => {
  try {
    const res = await API.put(`/admin/verifiedProduct/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = async (id) => {
  try {
    const res = await API.delete(`/api/products/deleteProductByID/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//videos
export const getAllVideos = async () => {
  try {
    const res = await API.get(`/video/getvideos`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getVideoById = async (id) => {
  try {
    const res = await API.get(`/video/getvideo/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addNewVideo = async (formData) => {
  try {
    const res = await multipartAPI.post(`/video/addVideo`, formData);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateVideoStatus = async (id, body) => {
  try {
    const res = await API.put(`/video/updateStatus/${id}`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateVideo = async (id, body) => {
  try {
    const res = await multipartAPI.put(`/video/updateVideo/${id}`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVideo = async (id) => {
  try {
    const res = await API.delete(`/video/deletevideo/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//gift
export const getAllGifts = async () => {
  try {
    const res = await API.get(`/gift/getAllGifts`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addNewGift = async (formData) => {
  try {
    const res = await multipartAPI.post(`/gift/createGift`, formData);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getGiftById = async (id) => {
  try {
    const res = await API.get(`/gift/getGiftById/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateGift = async (body) => {
  try {
    const res = await multipartAPI.put(`/gift/updateGift`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGift = async (id) => {
  try {
    const res = await API.delete(`/gift/deleteGiftById/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
