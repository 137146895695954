import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../components/Header";
import { getGiftById } from "../../api/axios.js";

function ViewGift() {
  const { id } = useParams();
  const [giftDetails, setGiftDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const GetGiftById = async (id) => {
    try {
      const data = await getGiftById(id);
      if (data.result.status) {
        setGiftDetails(data.result.result);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching gift:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetGiftById(id);
  }, [id]);

  return (
    <div>
      {loading ? (
        <Typography variant="h5" component="span" fontWeight="bold">
          Loading...
        </Typography>
      ) : (
        <Box sx={{ padding: "20px", overflowY: "auto", height: "90vh" }}>
          <Header title={`View Gift ${id}`} subtitle="" />
          <Box mt={"40px"}>
            <Grid container>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Grid item xs={3}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Gift Name:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {giftDetails.name?.charAt(0).toUpperCase() +
                      giftDetails.name?.slice(1)}
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={3}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Description:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {giftDetails.description?.charAt(0).toUpperCase() +
                      giftDetails.description?.slice(1)}
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={3}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Credit Count:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>{giftDetails.creditCount}</Typography>
                </Grid>
              </div>
            </Grid>
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={3}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Gift Type(physical or not):
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {giftDetails.isPhysicalGift === true ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </div>
            </Grid>
            <Grid container>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={3}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    Gift Image:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <img
                    src={giftDetails.image}
                    alt={`Image of ${giftDetails.name}`}
                    style={{ maxWidth: "100%" }}
                  />
                </Grid>
              </div>
            </Grid>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default ViewGift;
