import {
  Box,
  Button,
  TextField,
  Snackbar,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getGiftById, updateGift } from "../../api/axios";

const EditGift = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedGiftDetails, setUpdatedGiftDetails] = useState({
    name: "",
    description: "",
    creditCount: "",
    isPhysical: "",
    image: null,
  });
  const [errors, setErrors] = useState({});
  const [imageName, setImageName] = useState("");

  const GetGiftById = async (id) => {
    try {
      const data = await getGiftById(id);
      if (data.result.status) {
        setUpdatedGiftDetails({
          name: data.result.result.name,
          description: data.result.result.description,
          creditCount: data.result.result.creditCount,
          isPhysical: data.result.result.isPhysicalGift,
          image: data.result.result.image,
        });
        const imageUrl = data.result.result.image;
        const imageFileName = decodeURIComponent(
          imageUrl.split("/").pop().split("%20").pop().split("?")[0]
        );
        setImageName(imageFileName);
      }
    } catch (error) {
      console.error("Error fetching gift:", error);
    }
  };

  useEffect(() => {
    GetGiftById(id);
  }, [id]);

  const validateField = (name, value) => {
    const errors = {};

    if (name === "name" && !value) {
      errors.name = "Gift name is required.";
    }
    if (name === "description" && !value) {
      errors.description = "Description is required.";
    }
    if (name === "creditCount") {
      if (!value) {
        errors.creditCount = "Credit count is required.";
      } else if (value <= 0) {
        errors.creditCount = "Credit count must be greater than 0";
      }
    }
    if (name === "isPhysical" && !value) {
      errors.isPhysical = "Gift type is required.";
    }
    if (name === "image") {
      if (!value) {
        errors.image = "Image is required.";
      } else if (
        typeof value !== "string" &&
        !value.type.startsWith("image/")
      ) {
        errors.image = "Please enter a valid image type";
      }
    }

    return errors;
  };

  const handleInputChange = (name, value) => {
    setUpdatedGiftDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === "image" && value) {
      setImageName(value.name);
    }

    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldErrors[name] || "",
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!updatedGiftDetails.name) {
      errors.name = "Gift name is required.";
    }
    if (!updatedGiftDetails.description) {
      errors.description = "Description is required.";
    }
    if (!updatedGiftDetails.creditCount) {
      errors.creditCount = "Credit count is required.";
    } else if (updatedGiftDetails.creditCount <= 0) {
      errors.creditCount = "Credit count must be greater than 0";
    }
    if (updatedGiftDetails.isPhysical === "") {
      errors.isPhysical = "Gift type is required.";
    }
    if (!updatedGiftDetails.image) {
      errors.image = "Image is required.";
    } else if (
      typeof updatedGiftDetails.image !== "string" &&
      !updatedGiftDetails.image.type.startsWith("image/")
    ) {
      errors.image = "Please enter a valid image type";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateGift = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const body = {
        id: id,
        name: updatedGiftDetails.name,
        description: updatedGiftDetails.description,
        isPhysicalGift: updatedGiftDetails.isPhysical,
        creditCount: updatedGiftDetails.creditCount,
        image: updatedGiftDetails.image,
      };

      try {
        const response = await updateGift(body);
        if (response.result.status) {
          setAlertSeverity("success");
          setAlertMessage("Gift updated successfully");
          setTimeout(() => {
            navigate("/gifts");
          }, 2000);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(`Failed to gift update: ${error.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box sx={{ padding: "20px", height: "90vh", overflowY: "auto" }}>
      <Header title={`UPDATE GIFT ${id}`} subtitle="" />
      <Box mt="40px">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Gift Name*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedGiftDetails.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Description*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedGiftDetails.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              multiline
              rows={5}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Credit Count*
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              type="number"
              onChange={(e) => handleInputChange("creditCount", e.target.value)}
              value={updatedGiftDetails.creditCount}
              name="creditCount"
              error={!!errors.creditCount}
              helperText={errors.creditCount}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "E") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Gift Type(physical or not)*
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                value={updatedGiftDetails.isPhysical}
                onChange={(e) =>
                  handleInputChange("isPhysical", e.target.value)
                }
                name="isPhysical"
                error={!!errors.isPhysical}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
              {errors.isPhysical && (
                <Typography variant="caption" color="error">
                  {errors.isPhysical}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Upload an Image*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) => handleInputChange("image", e.target.files[0])}
                name="image"
                error={!!errors.image}
                helperText={errors.image}
              />
              {imageName && (
                <Typography variant="subtitle1" mt={1}>
                  {`Current image: ${imageName}`}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateGift}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditGift;
