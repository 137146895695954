import {
  Box,
  Button,
  TextField,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MuiAlert from "@mui/material/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { getProductById, updateProduct } from "../../api/axios";

const EditProduct = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const [updatedProductDetails, setUpdatedProductDetails] = useState({
    name: "",
    brand: "",
    modelNumber: "",
    energyConsumption: "",
    deviceType: "",
    image: null,
  });
  const [errors, setErrors] = useState({});
  const [imageName, setImageName] = useState("");

  const GetProductById = async (id) => {
    try {
      const data = await getProductById(id);
      if (data.status) {
        setUpdatedProductDetails({
          name: data.result.Name,
          brand: data.result.Brand,
          modelNumber: data.result.ModelNumber,
          energyConsumption: data.result.ProductWatt,
          deviceType: data.result.deviceType,
          image: data.result.proImage[0],
        });
        const imageUrl = data.result.proImage[0];
        const imageFileName = decodeURIComponent(
          imageUrl.split("/").pop().split("%20").pop().split("?")[0]
        );
        setImageName(imageFileName);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  useEffect(() => {
    GetProductById(id);
  }, [id]);

  const validateField = (name, value) => {
    const errors = {};

    if (name === "name" && !value) {
      errors.name = "Product name is required.";
    }
    if (name === "brand" && !value) {
      errors.brand = "Brand is required.";
    }
    if (name === "modelNumber" && !value) {
      errors.modelNumber = "Model number is required.";
    }
    if (name === "energyConsumption" && !value) {
      errors.energyConsumption = "Energy consumption is required.";
    }
    if (name === "deviceType" && !value) {
      errors.deviceType = "Device type is required.";
    }
    if (name === "image") {
      if (!value) {
        errors.image = "Image is required.";
      } else if (
        typeof value !== "string" &&
        !value.type.startsWith("image/")
      ) {
        errors.image = "Please enter a valid image type";
      }
    }

    return errors;
  };

  const handleInputChange = (name, value) => {
    setUpdatedProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    if (name === "image" && value) {
      setImageName(value.name);
    }

    const fieldErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldErrors[name] || "",
    }));
  };

  const validateForm = () => {
    console.log(updatedProductDetails);
    const errors = {};

    if (!updatedProductDetails.name) {
      errors.name = "Product name is required.";
    }
    if (!updatedProductDetails.brand) {
      errors.brand = "Brand is required.";
    }
    if (!updatedProductDetails.modelNumber) {
      errors.modelNumber = "Model number is required.";
    }
    if (!updatedProductDetails.energyConsumption) {
      errors.energyConsumption = "Energy consumption is required.";
    }
    if (!updatedProductDetails.deviceType) {
      errors.deviceType = "Device type is required.";
    }
    if (!updatedProductDetails.image) {
      errors.image = "Image is required.";
    } else if (
      typeof updatedProductDetails.image !== "string" &&
      !updatedProductDetails.image.type.startsWith("image/")
    ) {
      errors.image = "Please enter a valid image type";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdateProduct = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const body = {
        Name: updatedProductDetails.name,
        Brand: updatedProductDetails.brand,
        ModelNumber: updatedProductDetails.modelNumber,
        ProductWatt: updatedProductDetails.energyConsumption,
        deviceType: updatedProductDetails.deviceType,
        proImage: updatedProductDetails.image,
      };

      try {
        const response = await updateProduct(id, body);
        if (response.status) {
          setAlertSeverity("success");
          setAlertMessage("Product updated successfully");
          setTimeout(() => {
            navigate("/products");
          }, 2000);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(`Failed to product update: ${error.message}`);
      } finally {
        setOpenSnackbar(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box sx={{ padding: "20px", height: "90vh", overflowY: "auto" }}>
      <Header title={`UPDATE PRODUCT ${id}`} subtitle="" />
      <Box mt="40px">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Product Name*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedProductDetails.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Brand*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedProductDetails.brand}
              onChange={(e) => handleInputChange("brand", e.target.value)}
              error={!!errors.brand}
              helperText={errors.brand}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Device Type*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedProductDetails.deviceType}
              onChange={(e) => handleInputChange("deviceType", e.target.value)}
              error={!!errors.deviceType}
              helperText={errors.deviceType}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Energy Consumption(kWh/year)*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedProductDetails.energyConsumption}
              onChange={(e) =>
                handleInputChange("energyConsumption", e.target.value)
              }
              error={!!errors.energyConsumption}
              helperText={errors.energyConsumption}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Model Number*
            </Typography>
            <TextField
              type="text"
              fullWidth
              variant="filled"
              value={updatedProductDetails.modelNumber}
              onChange={(e) => handleInputChange("modelNumber", e.target.value)}
              error={!!errors.modelNumber}
              helperText={errors.modelNumber}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {" "}
            <Box>
              <div style={{ fontWeight: "bold" }}>Upload an Image*</div>
              <TextField
                fullWidth
                variant="filled"
                type="file"
                accept="image/*"
                onChange={(e) => handleInputChange("image", e.target.files[0])}
                name="image"
                error={!!errors.image}
                helperText={errors.image}
              />
              {imageName && (
                <Typography variant="subtitle1" mt={1}>
                  {`Current image: ${imageName}`}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
              onClick={handleUpdateProduct}
            >
              {isLoading ? "Updating..." : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditProduct;
