import {
  Button,
  TextField,
  Typography,
  Snackbar,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./Login.css";
import MuiAlert from "@mui/material/Alert";
import { SignIn } from "../../api/axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (name, value) => {

    setFormError({
      ...formError,
      [name]: "",
    });

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
        formData.password
      )
    ) {
      errors.password =
        "Password must include at least 8 characters, a symbol, a capital letter, a simple letter, and a number";
    } 

    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const body = {
          email: formData.email,
          password: formData.password,
        };

        const res = await SignIn(body);
        if (res.result.status) {
          setAlertSeverity("success");
          setAlertMessage("Sign in Successfull!");
          localStorage.setItem("token", res.result.token);
          localStorage.setItem("isAuthenticated", true);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        }
      } catch (err) {
        console.error(err);
        setAlertSeverity("error");
        setAlertMessage(
          `Sign in failed! Invalid Username or password : ${err.message}`
        );
      } finally {
        setOpenSnackbar(true);
        setLoading(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="login-container">
        <img src={logo}></img>
        <h2>Sign In</h2>
        <Typography component="p" variant="p">
          Please sign in to your accout
        </Typography>
        <form className="" onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={(e) => handleInputChange("email", e.target.value)}
            error={!!formError.email}
            helperText={formError.email}
          />
          <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="password"
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ marginBottom: "7px" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => handleInputChange("password", e.target.value)}
              error={!!formError.password}
            />
            <FormHelperText
              id="outlined-weight-helper-text"
              sx={{ color: "#d32f2f" }}
            >
              {formError.password}
            </FormHelperText>
          </FormControl>
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className=""
          >
            {loading ? "Signing In..." : "Sign In"}
          </Button>
        </form>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default Login;
