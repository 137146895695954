import { CssBaseline, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import { ColorModeContext, useMode } from "./theme";
import Products from "./scenes/ProductManagement/AllProcuts";
import Videos from "./scenes/VideoManagement/AllVideos";
import AddNewVideo from "./scenes/VideoManagement/AddNewVideo";
import ProductsFromUsers from "./scenes/ProductManagement/ProductsFromUsers";
import ViewVideo from "./scenes/VideoManagement/ViewVideo";
import EditVideo from "./scenes/VideoManagement/EditVideo";
import ViewProduct from "./scenes/ProductManagement/ViewProduct";
import Login from "./scenes/Login/Login";
import PrivateRoutes from "./Utils/PrivateRoutes";
import Gifts from "./scenes/GiftManagement/AllGifts";
import AddNewGift from "./scenes/GiftManagement/AddNewGift";
import ViewGift from "./scenes/GiftManagement/ViewGift";
import EditGift from "./scenes/GiftManagement/EditGift";
import AddNewProduct from "./scenes/ProductManagement/AddNewProduct";
import EditProduct from "./scenes/ProductManagement/EditProduct";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/products" element={<Products />} />
                <Route
                  path="/products-from-users"
                  element={<ProductsFromUsers />}
                />
                <Route
                  path="/products/create-product"
                  element={<AddNewProduct />}
                />
                <Route
                  path="/products/view-product/:id"
                  element={<ViewProduct />}
                />
                <Route
                  path="/products/edit-product/:id"
                  element={<EditProduct />}
                />
                <Route path="/videos" element={<Videos />} />
                <Route path="/videos/create-video" element={<AddNewVideo />} />
                <Route path="/videos/view-video/:id" element={<ViewVideo />} />
                <Route path="/videos/edit-video/:id" element={<EditVideo />} />
                <Route path="/gifts" element={<Gifts />} />
                <Route path="/gifts/create-gift" element={<AddNewGift />} />
                <Route path="/gifts/view-gift/:id" element={<ViewGift />} />
                <Route path="/gifts/edit-gift/:id" element={<EditGift />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
