import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  Snackbar,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { deleteProduct, getAllProducts } from "../../api/axios";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MuiAlert from "@mui/material/Alert";

const Products = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const GetAllProducts = async () => {
    try {
      const response = await getAllProducts();
      const capitalizedProducts = response.data.map((product) => ({
        ...product,
        Name: product.Name?.charAt(0).toUpperCase() + product.Name?.slice(1),
        Brand: product.Brand?.charAt(0).toUpperCase() + product.Brand?.slice(1),
        deviceType:
          product.deviceType?.charAt(0).toUpperCase() +
          product.deviceType?.slice(1),
      }));
      setProducts(capitalizedProducts);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching products", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllProducts();
  }, []);

  const handleViewClick = (id) => {
    navigate(`/products/view-product/${id}`);
  };

  const handleEditClick = (id) => {
    navigate(`/products/edit-product/${id}`);
  };

  const columns = [
    { field: "ProductID", headerName: "ID", flex: 0.5 },
    { field: "Name", headerName: "Product Name", flex: 1 },
    { field: "Brand", headerName: "Brand Name", flex: 1 },
    { field: "ModelNumber", headerName: "Model Number", flex: 1 },
    { field: "deviceType", headerName: "Device Type", flex: 1 },
    { field: "ProductWatt", headerName: "Energy", flex: 1 },
    {
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => (
        <Box>
          <Tooltip title="View">
            <IconButton onClick={() => handleViewClick(params.row.ProductID)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditClick(params.row.ProductID)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row.ProductID)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteProduct(id);
          if (response.status) {
            setAlertSeverity("success");
            setAlertMessage("Product deleted successfully");
            GetAllProducts();
          }
        } catch (error) {
          console.log(error);
          setAlertSeverity("error");
          setAlertMessage(`Failed to delete product: ${error.message}`);
        } finally {
          setOpenSnackbar(true);
        }
      }
    });
  };

  return (
    <Box
      sx={{
        padding: "20px",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="PRODUCTS" subtitle="List of all products" />
        <Box>
          <Link to={"create-product"}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6870fa",
                color: "white",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#3e4396",
                },
              }}
            >
              Add New Product
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        height="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={products}
          columns={columns}
          getRowId={(row) => row.ProductID}
          components={{ Toolbar: GridToolbar }}
          loading={loading}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          style={{ color: "white" }}
        >
          {alertSeverity === "success" ? "Success" : "Error"}
          {": "}
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Products;
